import React from "react";
import "./Profile.css";
const profilePicture = process.env.PUBLIC_URL + "/images/Mahbub-Alam-Masum.jpg";

function Profile() {
  return (
    <div className="profile-container">
      <div className="left-half">
        <img
          src={profilePicture}
          alt="Mahbub Alam Masum"
          className="profile-picture"
        />
      </div>
      <div className="right-half">
        <h1>Mahbub Alam Masum</h1>
        <h2>
          Computer Science Undergraduate | Pursuing Expertise in Full-Stack
          Development
        </h2>
        <p>
          Hello! I’m Masum, a Computer Science undergraduate currently pursuing
          my degree at{" "}
          <a href="https://www.google.com/search?q=iubat" target="blank">
            IUBAT - International University of Business Agriculture and
            Technology
          </a>
          . Currently, I am focused on mastering the MERN (MongoDB, Express.js, React,
          Node.js) stack and honing my skills in Data Structures and Algorithms
          (DSA) to improve my problem-solving abilities and coding efficiency.
          My goal is to become a proficient full-stack developer capable of
          creating impactful and innovative technology solutions.
        </p>
        <div className="social-icons">
          <a href="https://github.com/masum-dev" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://blog.masum.dev" target="_blank" rel="noopener noreferrer">
            <i className="far fa-pen-to-square"></i>
          </a>
          <a href="https://dev.to/masum-dev" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-dev"></i>
          </a>
          <a href="https://hashnode.com/@masum-dev" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-hashnode"></i>
          </a>
          <a href="https://www.linkedin.com/in/masumdev" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://twitter.com/masum_dev" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href="https://www.instagram.com/masum.dev" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Profile;
